// export default function (res: any, name = "") {
//     const url = window.URL.createObjectURL(new Blob([res], { type: "application/vnd.ms-excel" }));
//     const link = document.createElement("a");
//     link.style.display = "none";
//     link.href = url;
//     const timestamp= name;
//     link.download = timestamp + ".sql.gz";
//     document.body.appendChild(link);
//     link.click();
//     document.body.removeChild(link);
//     window.URL.revokeObjectURL(url);
// }
export default function (res, name = "") {
    const url = window.URL.createObjectURL(new Blob([res], { type: "application/vnd.ms-excel" }));
    const link = document.createElement("a");
    link.style.display = "none";
    link.href = url;
    const timestamp= name || new Date().getTime();
    link.download = timestamp + ".xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
	return true;
}
// export default function (data:any, type:any, fileName='') {
//     let blob = new Blob([data], {type: `application/${type};charset=utf-8`});
//     // 获取heads中的filename文件名
//     let downloadElement = document.createElement('a');
//     // 创建下载的链接
//     let href = window.URL.createObjectURL(blob);
//     downloadElement.href = href;
//     // 下载后文件名
//     downloadElement.download = fileName;
//     document.body.appendChild(downloadElement);
//     // 点击下载
//     downloadElement.click();
//     // 下载完成移除元素
//     document.body.removeChild(downloadElement);
//     // 释放掉blob对象
//     window.URL.revokeObjectURL(href);
// }

