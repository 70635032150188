<template>
  <div class="get_m_a_s">
    <!-- 筛选 -->
    <div class="filtrate">
      <div class="header-title">
        <p>筛选</p>
      </div>
      <div class="header-from">
        <el-form
          :inline="true"
          :model="queryData"
          class="demo-form-inline"
          size="mini"
          ref="queryData"
        >
          <el-form-item label="量表类型" prop="id">
            <el-select v-model="queryData.gid" filterable>
              <el-option
                :label=" 'A' "
                :value="'A'"
              ></el-option>
              <el-option
                  :label=" 'B' "
                  :value="'B'"
              ></el-option>
              <el-option
                  :label=" 'C' "
                  :value="'C'"
              ></el-option>
              <el-option
                  :label=" 'D' "
                  :value="'D'"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="科室部门" prop="department_id">
            <el-select v-model="queryData.did" filterable>
              <el-option
                v-for="item in departmentList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
                >{{ item.name }}</el-option
              >
            </el-select>
          </el-form-item>
          <el-form-item label="姓名">
            <el-input v-model="queryData.name"  maxlength="10"></el-input>
          </el-form-item>
          <el-form-item label="时间范围" prop="date">
            <!-- <el-date-picker
              v-model="date"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="timestamp"
            ></el-date-picker> -->
            <el-date-picker
                  v-model="date[0]"
                  type="date"
                  placeholder="开始日期"
                  value-format="timestamp"
                >
            </el-date-picker>
              <span style="padding-right:16px">至</span>
            <el-date-picker
              v-model="date[1]"
              type="date"
              placeholder="结束日期"
              value-format="timestamp"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button plain @click="getTable">查询</el-button>
            <el-button @click="resetForm()">重置</el-button>
            <el-button @click="download1()">下载</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- 表格 -->
    <div class="content">
      <el-table ref="multipleTable" type="selection"  :data="tableData"   @selection-change="handleSelectionChange" tooltip-effect="dark"
                style="width: 100%"
                max-height="770"
                stripe
                border
                class="tableBox">
        <!--------------------------------------- 二级表格------------------------------------------ -->

        <!-- -------------------------------------------------------------------------------------- -->
        <el-table-column
            type="selection"
            width="55"
            align="center"
        ></el-table-column>
        <el-table-column
          label="科室"
          prop="name"
          align="center"
        ></el-table-column>
        <el-table-column label="姓名" prop="real_name" align="center">
        </el-table-column>
<!--        <el-table-column label="测评操作总量" prop="num" align="center"></el-table-column>-->
<!--        <el-table-column label="患者量" prop="user_num" align="center">-->

<!--        </el-table-column>-->
        <el-table-column label="量表名称"  width="350" prop="guagename" align="">

        </el-table-column>
        <el-table-column label="医保类型" prop="edit_class" align="center">
          <!--          <template slot-scope="scope">-->
          <!--            <div>-->
          <!--              {{ classABC(scope.row,'A',true)}}-->
          <!--            </div>-->
          <!--          </template>-->
        </el-table-column>
        <el-table-column label="报告总数" prop="num"  align="center" >
        </el-table-column>
        <el-table-column label="量表价格(元)" prop="price" align="center">
        </el-table-column>
        <el-table-column label="合计" prop="price" align="center">
                    <template slot-scope="scope">
                      <div>
                        {{ classABCnew(scope.row,true)}}
                      </div>
                    </template>
        </el-table-column>


      </el-table>
      <div class="block">
        <el-pagination

            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-sizes="[50,100,150,200]"
            :page-size="50"
            layout="total, sizes, prev, pager, next, jumper"
            :total="this.count">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { loadArchivesInfo } from "@/api/evaluate.js";
import downLoad from "@/utils/downLoad";
import { loadDepartmentData } from "@/api/comm.js";
import {downloadwork, get_measure_user_statistics, get_work_eva_sta} from "@/api/user_info_statistical.js";
import axios from "axios";
export default {
  data() {
    return {
      scaleData: [],
      departmentList: [],
      formInline: {},
      scaleList: [],
      tableData: [
        { title: "拉" },
        { title: "拉" },
        { title: "拉" },
        { title: "拉" },
        { title: "拉" },
        { title: "拉" },
        { title: "拉" },
        { title: "拉" },
        { title: "拉" },
        { title: "拉" },
        { title: "拉" },
      ],
      count:0,
      date: [],
      queryData: {
        did: "",
        gtype:'',
        name:'',
        min: "",
        stime: "",
        etime: "",
      },
      currentPage4:1,
      limit:50,
      page:1
    };
  },
  created() {
    // 获取量表列表
    this.loadScaleData();
    // 获取所有部门
    loadDepartmentData().then((res) => {
      this.departmentList = res.data.data;
    });
    this.getTable();
  },
  methods: {
    classABC(row,edit_class,price = false){
      if (row.edit_class == edit_class){
        return  price ? (row.num ?row.price * row.num :row.price) :row.num;
      }
      return 0;
    },
    classABCnew(row,price = false){
        return  price ? (row.num ?row.price * row.num :row.price) :row.num;
    },
    handleSizeChange(val) {
     this.limit=val
    this.getTable()
    },
    handleCurrentChange(val) {
     this.page=val
      this.getTable()
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.download()
    },
    //存入缓存
    async download(){
      let data = this.multipleSelection
      downloadwork(data).then((res) => {
        downLoad(res.data,'下载')
      });
    },
    //下载
    async download1(){
      if(this.multipleSelection ===undefined){
        this.$message({
          message: "请勾选下载内容!",
          type: "error",
        });
        return

      }
      let data = {
        download:1
      }
      window.open('/download_work?download=1')

    },
    // 量表数据请求
    async loadScaleData() {
      var res = await axios.get("/all_measure_classify");
      if (res.data.code === 400200) {
        this.scaleData = res.data.data;
        res.data.data.forEach((v) => {
          v.status_measure.forEach((v) => {
            this.scaleList.push(v);
          });
        });
      } else {
        return;
      }
    },

    //
    getTable() {
      // console.log(this.date);
      if(this.date[0]||this.date[1]){
        if(!this.date[0]||!this.date[1]){
          this.$message({
            message: "请选择完整时间范围或者不选择时间范围!",
            type: "error",
          });
          return
        }
      }
      let stime = "";
      let etime = "";
      if (this.date) {
        stime = this.date[0] / 1000;
        etime = this.date[1] / 1000;
      }
      // 时间处理
      let data = {
        did: this.queryData.did || null,
        gid: this.queryData.gid || null,
        stime: stime || null,
        etime: etime || null,
        name: this.queryData.name || null,
        pages:this.page,
        limit:this.limit
      };
      get_work_eva_sta(data).then((res) => {
        console.log(res)
        this.tableData = res.data;
        this.count = res.count
      });
    },
    getchartData() {},
    resetForm(formName) {
      this.queryData = {
        did: "",
        mid: "",
      };
      this.date = [];
    },
  },
};
</script>
<style lang="less" scoped>
.block {
  // .el-pagination {
  //   position: absolute;
  //   bottom: 0;
  //   right: 0;
  // }
  //width: 100px;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;

  //input.el-input__inner{}
}
/deep/ .el-input.el-input--mini.el-input--suffix>.el-input__inner{
    width: 100px;
}
/deep/ .el-table td, .el-table th{
 padding: 6px 0;
}
.get_m_a_s {
  width: 100%;
  height: calc(~"100% - 126px");
  padding-bottom: 10px;
  .el-date-editor.el-input, .el-date-editor.el-input__inner{
    width: 140px;
  }
  // 筛选
  .filtrate {
    display: flex;
    width: 100%;
    height: 50px;
    background: rgb(217, 242, 247);
    margin-top: 20px;
    .header-title {
      color: rgb(4, 87, 102);
      width: 56px;
      text-align: center;
      border-right: 1px solid rgb(127, 222, 243);
    }
    p {
      line-height: 50px;
      font-size: 14px;
    }
    .header-from {
      padding-left: 10px;
      display: flex;
      align-items: center;
      .el-input__inner {
        width: 120px;
        padding-right: 20px;
      }
      .el-form-item:nth-child(3) .el-input__inner {
        // width: 230px;
        padding-right: 0;
      }
      .el-form-item {
        margin-bottom: 0;
      }
      .el-form-item__label {
        color: rgb(4, 87, 102);
      }
      .el-input__icon {
        width: 15px;
      }
      .el-button--mini {
        padding: 5px 10px;
        background: transparent;
        color: rgb(4, 87, 102);
        font-size: 16px;
        &:hover {
          border: 1px solid rgb(105, 105, 105);
        }
      }
    }
  }
  .content {
    position: relative;
    height: calc(~"100% - 220px");
    min-height: 745px;
    margin-top: 20px;
    color: rgb(4, 87, 102);
    th {
      color: rgb(4, 87, 102);
      padding: 0;
      height: 40px;
      line-height: 40px;
      background: rgb(217, 242, 247);
    }
    td {
      padding: 0;
      height: 35px;
      line-height: 35px;
    }
    tr.el-table__row--striped td {
      background: rgb(234, 246, 248);
    }
    .el-button + .el-button {
      margin-left: 0px;
    }
    .el-button--mini {
      padding: 4px 4px;
      background: transparent;
      color: rgb(4, 87, 102);
      font-size: 14px;
      &:hover {
        border: 1px solid rgb(105, 105, 105);
      }
    }
    .no {
      color: green;
    }
    .off {
      color: red;
    }
    .el-input__inner {
      width: 60px;
      padding: 5px;
      text-align: center;
    }
  }
  .el-table__footer-wrapper {
    .el-table__footer {
      .has-gutter {
        tr {
          td {
            background: rgb(252, 218, 28);
            color: #3c3c3c;
            font-size: 16px;
            font-weight: 700;
          }
        }
      }
    }
  }
  .tableBox {
    margin-top: 20px;
    color: rgb(4, 87, 102);
    .no {
      color: green;
    }
    .off {
      color: red;
    }
    th {
      color: rgb(4, 87, 102);
      padding: 0;
      height: 40px;
      line-height: 40px;
      background: rgb(217, 242, 247);
    }
    td {
      padding: 0;
      height: 35px;
      line-height: 35px;
    }
    tr.el-table__row--striped td {
      background: rgb(234, 246, 248);
    }
    .el-button + .el-button {
    }
    .el-button--mini {
      padding: 4px 4px;
      background: transparent;
      color: rgb(4, 87, 102);
      font-size: 14px;
      &:hover {
        border: 1px solid rgb(105, 105, 105);
      }
    }
  }
}
</style>